<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="notifications"
      :items-per-page="15"
      class="elevation-3 mx-lg-10"
      mobile-breakpoint="100"
      calculate-widths
      hide-default-footer
      :item-class="row_class"
      @click:row="setReadSelectedNotification"
    >
      <template
        v-slot:top
      >
        <v-row
          class="mb-n13 pb-0"
        >
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="py-0"
          >
            <v-row>
              <v-col class="text-h2 pl-8 mb-n5">
                Notifikasi
              </v-col>
            </v-row>
            <v-row>
              <v-col class="font-weight-medium pl-8">
                Berikut ini adalah informasi notifikasi anda.
              </v-col>
            </v-row>
          </v-col>

          <v-spacer />

          <v-col
            cols="12"
            sm="6"
            md="4"
            class="pl-8 pr-0 py-8 text-right"
          >
            <div class="d-flex justify-end">
              <a
                href="#"
                style="text-decoration:none;"
                @click="setReadAllNotification"
              >
                <p
                  class="font-weight-bold text-h4 my-3 mr-10"
                  style="color: purple;"
                >
                  Tandai Semua Telah Dibaca
                </p>
              </a>
              <div />
            </div>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.title`]="{ item }">
        <div>
          <p class="font-weight-bold on-hover-link mb-0">
            {{ item.title }}
          </p>
        </div>
      </template>

      <template v-slot:[`item.content`]="{ item }">
        <div>
          <p class="on-hover-link mb-0">
            {{ item.content }}
          </p>
        </div>
      </template>

      <template v-slot:[`item.date`]="{ item }">
        <div>
          <p class="on-hover-link mb-0">
            {{ item.date }}
          </p>
        </div>
      </template>

      <template v-slot:footer>
        <div class="text-center my-5 py-3">
          <!-- <span>Rows per page</span>
          <div
            class="d-inline-flex"
            style="width: 50px;"
          >
            <v-select
              :items="rowsPerPage"
              :label="rowsPerPage[0]"
              dense
              color="purple"
            />
          </div> -->

          <v-pagination
            v-model="page"
            :length="pageCount"
            color="purple"
            @input="onChangePage"
          />

          <span style="font-size: 12px; font-style: italic;">
            Page {{ page }} of {{ pageCount || 1 }}
          </span>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      notification: 'Notification',
      headers: [
        { text: '', value: 'title', sortable: false },
        { text: '', value: 'content', sortable: false },
        { text: '', value: 'date', align: 'end', sortable: false },
      ],
      notifications: [],
      page: 1,
      pageCount: 0,
      rowsPerPage: [15, 30, 50],
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),
    },

    watch: {
    },

    created () {
      this.initialize(1, 30)
    },

    methods: {
      initialize (page, limit) {
        const vm = this

        const requestBody = {
          get_type: 0,
          page: page,
          limit: limit,
        }

        axios.post('/v1/g/notification', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.notifications = res.data.data.notification
            vm.pageCount = res.data.data.pagination.page_total
            vm.rowsPerPage = res.data.data.pagination.limit_arr
          }
        }).catch((e) => {
        })
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      async setReadSelectedNotification (item) {
        const requestBody = {
          read_all: 0,
          notif_id: item.id,
        }

        axios.post('/v1/g/readNotification', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })

        this.initialize(1, 30)
      },

      async setReadAllNotification () {
        const requestBody = {
          read_all: 1,
        }

        await axios.post('/v1/g/readNotification', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })

        this.initialize(1, 30)
      },

      row_class (item) {
        if (item.read === false) {
          return 'purple lighten-5'
        }
      },
    },
  }
</script>

<style scoped>
  .on-hover-link:hover {
    cursor: pointer;
  }
</style>
